.confirm-modal {
  top: 35%;
  .rs-btn-close {
    color: transparent !important;
  }
  .rs-modal-title {
    color: #324051;
    font-weight: 500;
    font-size: 20px;
    height: 25px;
    text-align: center;
    letter-spacing: 0;
  }
  .rs-modal-body {
    text-align: center;
  }
  .rs-modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .close {
    font-weight: 600;
    background-color: #fff; /* Change to your desired color */
    color: #141f2d;
    border: 1px solid #141f2d;
    height: 48px;
  }
  .continue {
    font-weight: 600;
    height: 48px;
  }
}
