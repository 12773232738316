//Overrides
$primary: #e0102a;
$grey-f1: #768ab1;
$grey-f2: #495472;
$grey-f3: #ededf3;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";

.bg-grey {
  background: #e0e1e3;
  border-radius: 1.5rem;
  padding: 1.5rem;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: $grey-f2;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: $grey-f2;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

h6 {
  font-size: 1.2rem;
  font-weight: 600;
  color: $grey-f2;
}

.bg-white {
  background-color: #ffffff !important;
}

.form-control,
.form-select {
  font-size: 12px;
}

.form-control {
  font-size: 1.2rem;
  --bs-secondary-color: #929eb8;
}

.form-label {
  font-size: 1.3rem;
  font-weight: 400;
}

.empty {
  background-color: $grey-f3;
  height: 22rem;
  justify-content: center;
  align-items: center;

  img {
    width: 5rem;
  }
}
