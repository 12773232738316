.header {
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  height: 6rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #edeff2;
}

.header-property-select {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 2rem;
  #propertySelect {
    color: #7d8fb3;
    font-size: 1.3rem;
    padding: 0.7rem 3rem 0.5rem 0.5rem;
  }
}

.header-notification-container {
  margin-left: auto;
}

.logo {
  height: 3.2rem;
}

.home-btn {
  border: none;
  background: none;
}

.menu-icon {
  color: #c3cad9;
  cursor: pointer;
}

.menu-icon:hover {
  color: #c3cad9;
}

.my-docs {
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #6b7a99;
  text-decoration: none;
  margin-left: 5px;
}

.dropdown-main .btn,
.dropdown-main .btn:hover,
.dropdown-main .btn:active,
.dropdown-main .btn:focus {
  background-color: transparent;
  border: 0;
}
.dropdown-option {
  width: 150px;
  height: 40px;
  font-size: 12px;
  padding-top: 10px;
}
.dropdown-option i {
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 14px;
}
