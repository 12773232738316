.right-panel-container {
  .right-panel-header-one {
    font-size: 20px;
    font-weight: 600;
    color: #324051;
    line-height: 30px;
    padding: 0;

    .bulk-header {
      max-width: 100%;
    }
  }

  .date-clear {
    font-size: 22px;
    line-height: 24px;
    cursor: pointer;
  }

  .right-panel-header-two {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #324051;
    line-height: 22px;
  }

  .header-rateplan-name {
    padding: 0;
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rooms-container {
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #eaf0f5;
    color: #324051;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-left: 0;

    > :first-child {
      border-right: 1px solid white;
    }

    div {
      text-align: center;
    }
  }

  .date-update-form {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #e8e9ed;
    border-radius: 8px;

    .allotment-message {
      margin-bottom: 30px;
      border-radius: 8px;
      padding: 8px 12px;
      font-size: 16px;
    }

    .allotment-warning {
      background-color: #f9edee;
      color: #c25254;
    }

    .allotment-success {
      background-color: #e9f5ec;
      color: #5a9764;
    }

    .rs-row {
      height: 40px;
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;
      color: #324051;

      .row-label {
        font-weight: 600;
      }
      input {
        border-radius: 8px;
        height: 40px;
        box-shadow: none;
        font-size: 14px;
        padding-left: 12px;
        border: 1px solid #c1cdda;
      }
    }

    .error-input-row {
      .row-label {
        color: #c25254;
      }
      input {
        color: #c25254;
        border-color: #c25254;
      }
    }
  }

  .form-buttons {
    display: flex;
    margin: 20px 0;
    justify-content: space-evenly;
    position: relative;
    top: 29px;
    button {
      border: 1px solid #c1cdda;
      border-radius: 12px;
      height: 40px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .cancel-btn,
    .save-btn {
      font-weight: 600;
      height: 48px;
      border-radius: 8px;
    }

    .cancel-btn {
      border: 1px solid #082b42;
      color: #082b42;
      background: none;
    }

    .save-btn {
      background-color: #ff5a37;
      color: white;
      margin-left: 15px;
      border: none;
    }
  }

  .ari-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    padding: 20px;
    gap: 10px;
    border-radius: 8px;
    background-color: #f8fafc;
    color: #324051;

    &__label {
      font-weight: 500;
      line-height: 21px;
    }

    &__value {
      font-weight: 500;
      line-height: 21px;
    }
  }
}
