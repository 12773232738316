.rateplan-container {
  display: flex;
  position: relative;
  right: 5px;
  .rateplan {
    padding-right: 16px;
    color: #324051;
    font-weight: 600;
    display: flex !important;
    align-items: center;
  }
  .rs-picker-toggle-value {
    color: #324051 !important;
  }

  .rs-form-control-label {
    margin-bottom: 15px !important;
  }

  .rs-picker-toggle {
    position: relative;
    bottom: 8px;
  }
  .rs-picker-toggle-textbox {
    height: 48px;
  }
}
