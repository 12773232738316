.create-promo {
  .create-promo-title {
    margin-top: 2rem;
  }
  .create-promo-label {
    margin-bottom: 2rem;
  }
  .create-promo-input {
    padding: 1.5rem;
    border-radius: 5px;
    font-size: 1.2rem;
    --bs-secondary-color: #929eb8;
  }
  .create-promo-footer {
    margin: 2rem 0;
    .next-btn {
      background-color: #344a6f;
      width: 200px;
      padding: 1rem;
      border: 0;
    }
    .next-btn.disabled {
      background-color: #dadfee;
      cursor: not-allowed;
    }
    .go-back-btn {
      color: #3a5edd;
      text-decoration: none;
    }
    .ignore-btn {
      background-color: #344a6f;
      width: 200px;
      padding: 1rem;
      border: 0;
    }
    .save-as-btn {
      background-color: #e5eaf3;
      color: #344a6f;
      width: 120px;
      padding: 1rem;
      border: 0;
      margin-left: 1rem;
    }
  }
  .canned-promo-container {
    .canned-promo-header {
      color: #929eb8;
      margin-bottom: 3rem;
      margin-top: 1rem;
    }
    .canned-promo-wrapper {
      padding: 3rem 2rem;
      border: 1px solid #dadfee;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 220px;
      border-radius: 5px;
    }
    .canned-header {
      color: #344a6f;
      font-size: 1.6rem;
      font-weight: 400;
    }
    .canned-desc {
      color: #929eb8;
      font-size: 12px;
    }
    .canned-footer {
      color: #344a6f;
      font-size: 12px;
      cursor: pointer;
      .fa {
        margin-left: 2rem;
        font-size: 18px;
        position: relative;
        top: 2px;
      }
    }
  }
  .summary-body {
    .summary-desc {
      color: #929eb8;
      font-size: 1.2rem;
    }
    .overlap-body {
      display: flex;
      margin-bottom: 4rem;
      border-radius: 10px;
      padding: 3rem 4rem;
      color: #344a6f;
      border-bottom: 1px solid #e5eaf3;

      .fa {
        font-size: 5rem;
        color: #fac967;
        margin-top: 1.5rem;
      }
      .overlap-body-details {
        margin-left: 2rem;
      }
      .overlap-body-header {
        font-weight: 500;
        font-size: 2rem;
      }
      .overlap-body-desc {
        font-size: 1.2rem;
        font-weight: 400;
        margin-top: 0;
        line-height: 16px;
      }
    }
  }
  .summary-promo-title {
    color: #344a6f;
    font-size: 1.4rem;
    .fa {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
  .accordion-wrapper {
    margin-top: 2rem;
    .accordion-item {
      background-color: transparent;
      padding: 1rem 0;
      border: 0;
    }
    .accordion-button {
      background-color: #e5eaf3;
      padding: 2.5rem;
      color: #344a6f;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .accordion-button::after {
      color: #344a6f;
    }
    .accordion-button:not(.collapsed) {
      background-color: #344a6f;
      color: #ffffff;
    }
    .accordion-button:not(.collapsed)::after {
      color: #344a6f;
    }
  }
}

.targetting-conditions {
  .rs-picker-popup {
    z-index: 9999 !important;
  }
  .tc-section {
    padding: 1rem;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    .rs-picker-popup {
      z-index: 9999 !important;
    }
    .tc-section-title {
      display: flex;
      margin-bottom: 1rem;
      .title {
        margin-right: auto;
      }
    }
    .staythrough {
      padding: 1rem;
      background: #c3cad9;
    }
    .staythrough.allow {
      background: #344a6f;
      color: #ffffff;
    }
    .rs-dropdown,
    .rs-dropdown-toggle {
      width: 100%;
    }
  }
}

.rooms-rateplans {
  .check-span {
    font-size: 16px;
  }
}

.offer-type {
  .day-input-container {
    width: 6rem !important;
  }
  .day-input {
    font-size: 1.3rem;
    padding-right: 0;
    padding-left: 0.8rem;
    text-align: center;
  }
  .rs-input-group-addon {
    padding: 10px 5px 10px 5px !important;
  }
  .day-input::-webkit-outer-spin-button,
  .day-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .day-input[type="number"] {
    -moz-appearance: textfield;
  }
  .day-input-note {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding-left: 3rem;
  }
}
