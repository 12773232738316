.daterange-container {
  display: flex;
  .daterange {
    display: flex !important;
    align-items: center;
    padding-right: 16px;
    color: #324051;
    font-weight: 600;
  }
  .rs-input {
    color: #324051 !important;
    font-weight: 400;
  }
}
