.bulk-update-container {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 20px;

  .bulk-update-header {
    color: #324051;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .single-row {
    margin-top: 1rem;
    &.row-btn {
      margin-top: 2rem;
    }
  }

  .roomtype-picker {
    width: 200px;
  }

  .rs-form-control-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #324051;
    margin-bottom: 8px !important;
  }

  .rs-form-group .rs-input {
    width: 100%;
    height: 48px;
    border-color: #c1cdda;
    padding-left: 12px;
    padding-right: 12px;
  }

  .rs-toggle {
    width: 100%;
  }

  .rs-toggle-label {
    color: #324051;
  }

  .rs-toggle-checked .rs-toggle-presentation {
    background-color: #5f70ee;
  }

  .rs-btn {
    padding: 8px 16px;
    height: 48px;
    line-height: 34px;
    padding-left: 12px;
    border-color: #c1cdda;
    &.rs-btn-primary {
      background-color: #ff5a37;
      border-color: #ff5a37;
      color: #fff;
      font-weight: 500;
      height: 40px;
    }
    .rs-picker-caret-icon {
      top: 13px !important;
    }
  }

  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    width: 100%;
  }

  .rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
  }
}

.all-room-checkbox {
  border-bottom: 1px solid #e8e9ed;
  padding: 6px 0;
  margin: 6px 0;
}

.form-container {
  margin-top: 20px;
}
.rs-picker-daterange-calendar-group {
  .rs-calendar-table-cell-content {
    color: #324051;
  }
}
.rs-picker-check-menu {
  .rs-checkbox-label {
    color: #324051;
  }
}
