.ht-loading {
  img {
    width: 61px;
  }

  .shine {
    overflow: hidden;
    background: linear-gradient(
      to right,
      fade_out(#fff, 1) 0%,
      fade_out(#fff, 0.7) 100%
    );
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
    animation: shine 0.85s cubic-bezier(0.91, 0.88, 0, 1.22) 0s infinite;

    @keyframes shine {
      100% {
        left: 115%;
      }
    }
  }
}
