.tab-content {
  gap: 0;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 5px;

  &.with-tag-picker {
    .rs-nav-tabs,
    .tablistBar {
      width: calc(100% - 225px);
    }
  }

  .rs-nav-tabs {
    overflow-x: auto;
    padding: 0;

    .rs-nav-item {
      padding: 12px 20px;
      background-color: #eaf0f5;
      margin-right: 0.1rem;

      &-active {
        background-color: #fff;
        color: #5f70ee;
        font-weight: 600;
      }
    }
  }

  .rs-tab-panel {
    padding-top: 1rem;
  }
}

.badge {
  --bs-badge-padding-x: 6px;
  --bs-badge-padding-y: 6px;
  --bs-bg-opacity: 1;
  &__sold-out {
    background-color: #c25254;
    margin-right: 8px;
  }
}

.tab-soldOut-icon {
  padding: 5px !important;
  margin-right: 12px !important;
}

.roomtype-picker {
  position: absolute;
  top: 5px;
  right: 20px;
  width: 200px;
}

.rs-picker-popup {
  --rs-listbox-option-hover-text: #324051;
  --rs-checkbox-checked-bg: #5f70ee;

  .badge {
    margin-left: 5px;
    margin-right: 0;
    float: right;
    --bs-badge-padding-x: 6px;
    --bs-badge-padding-y: 6px;
  }
}

.rs-picker-check-menu-items
  .rs-check-item.rs-checkbox-checked
  .rs-checkbox-checker
  > label {
  font-weight: 400;
}
