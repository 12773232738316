.success-block {
  background: #e9f5ec;
  padding: 10px;
}

.file {
  color: #5a9764;
  position: relative;
  bottom: 17px;
  left: 30px;
}

.success-icon {
  position: relative;
  top: 9px;
}

.success-text {
  color: #5a9764;
  position: relative;
  left: 30px;
  font-size: 11px;
  white-space: nowrap;
  bottom: 16px;
}

.failure-block {
  background: red;
  padding: 10px;
}
