.res-container {
  .page-header {
    color: #324051;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1.5%;
    margin-bottom: 20px;
  }

  .searched-field {
    display: flex;
    flex-direction: row;
    padding-right: 2rem;
    padding-left: 1.5rem;
    border-left: #c1cdda 1px solid;

    &:first-of-type {
      padding-left: 5px;
      border-left: 0;
    }

    .icon {
      align-content: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: #000;
      padding: 0.5rem 1rem 0.5rem 0;
    }

    .data {
      display: flex;
      flex-direction: column;

      .label {
        font-size: 1.2rem;
      }

      .field {
        color: #000;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }

  .reservation-data {
    background-color: #fff;
    margin: 1.5rem -2.75rem 0 -2.75rem;

    .reservation-data-body {
      padding: 2.75rem;

      .rs-table-cell {
        background-color: #fff;
        color: #19191a;
      }
      .rs-table-cell-content {
        font-size: 14px;
      }
      .table-wrapper [role="columnheader"] {
        color: #324051;
        font-weight: 600;
        font-size: 14px;
      }

      .repush {
        cursor: pointer;
        text-decoration: underline;
        color: #0f9aee;
        background-color: transparent;
      }

      .repush-success {
        .rs-message-container {
          font-size: 12px;
          padding: 20px 28px;
          margin: -15px;
          flex-direction: row-reverse;
          .rs-message-icon {
            margin-right: 5px;
          }

          .rs-message-icon .rs-icon {
            font-size: 12px;
          }
          .rs-message-body {
            color: #5a9764;
          }
        }
      }

      .status {
        color: #0b121a;
        border-radius: 10px;
        padding: 0 1rem;
        &.active {
          background-color: #73ba7f;
        }
        &.cancelled {
          background-color: #d7777f;
        }
      }

      .filters {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;

        span {
          .icon {
            width: 1.2rem;
            padding-bottom: 0.2rem;
            margin-right: 0.7rem;
          }

          color: #324051;
          font-size: 14px;
          line-height: 21px;
          align-self: center;
        }

        .dropdown {
          margin-left: 2rem;
          width: 250px;
        }

        .button-section {
          margin-left: auto;

          .rs-btn-primary {
            background-color: #324051;
            margin-left: 2rem;
          }
        }
      }

      .upload-panel {
        position: fixed;
        right: 0;
        top: 19rem;
        width: 380px;
        height: calc(100% - 70px);
        padding: 20px 32px;
        background-color: #fff;
        box-shadow: 0px 4px 12px 0px #3240514d;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 500;

        &.show {
          transform: translateX(0);
        }

        .upload-header {
          display: flex;
          justify-content: space-between;
          span {
            display: block;
            width: 200px;
            font-size: 22px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: -0.015em;
            text-align: left;
            color: #324051;
          }

          .close-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }

        .upload-body {
          color: #324051;
          overflow-y: scroll;
          height: calc(100vh - 290px);
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: -0.015em;
            margin-bottom: 20px;
          }

          .modal-link {
            color: #0f9aee;
            button {
              cursor: pointer;
              background-color: transparent;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: -0.005em;
              text-align: left;
              text-decoration-line: underline;
              text-decoration-style: solid;

              &:first-of-type {
                padding-right: 10px;
                border-right: 1px solid #000000;
              }
              &:last-of-type {
                padding-left: 10px;
              }
            }
          }

          hr {
            border-top: 1px solid #c1cdda;
          }

          .export-btn {
            margin: 0.5rem 0 20px 0;
            background-color: #324051;
          }

          .upload-section {
            margin-top: 1rem;
          }

          .upload-btn {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.res-drawer {
  background-color: #f4f6f8;
}

.res-form-container {
  .page-header {
    color: #324051;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1.5%;
    margin-bottom: 20px;
  }

  .rs-row {
    margin-top: 20px;
  }

  .rs-form-vertical .rs-form-group .rs-input-group {
    width: auto;
  }

  .rs-form-group {
    width: auto;
  }

  .rs-form-control-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0;
    color: #324051;
  }
}

.show-hide-column-modal {
  .rs-modal-body {
    border-top: 1px solid #c1cdda;

    .show-hide-cell {
      margin-top: 1rem;
      border-bottom: 1px solid #c1cdda;
      .rs-checkbox-label {
        font-size: 14px;
      }
    }
  }

  .rs-row {
    margin: 0;
  }

  .rs-modal-footer {
    padding-top: 2rem;
    border-top: 1px solid #c1cdda;
  }
}

.description-modal {
  .rs-modal-body {
    border-top: 1px solid #c1cdda;
    padding: 2rem 0;
    b {
      font-weight: 600;
    }
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
    }
  }
}

.show-hide-column-modal,
.res-form-container,
.res-container {
  .rs-btn {
    padding: 8px 16px;

    &.rs-btn-ghost {
      border-color: #082b42;
      color: #082b42;
      height: 40px;
      font-weight: 600;
    }

    &.rs-btn-primary {
      background-color: #ff5a37;
      border-color: #ff5a37;
      color: #fff;
      height: 40px;
      font-weight: 600;
    }
    &.rs-btn-disabled {
      height: 40px;
      font-weight: 600;
    }
  }
}
