.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rs-table-cell-content {
  justify-content: flex-start !important;
  align-content: center;
}

.table-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  [role="columnheader"] {
    font-weight: 700;
  }

  .rs-table-row-header {
    background-color: rgba(247, 249, 252, 0.8) !important;
    border-top: 2px solid #e9edf5 !important;
    border-bottom: 2px solid #e9edf5 !important;
    .rs-table-cell {
      background-color: rgba(247, 249, 252, 0.8) !important;
    }
  }

  .rs-table-row {
    border-bottom: 1px solid #e9edf5 !important;
  }

  .disabled-table-row {
    background-color: #f3f6f8;
    [role="gridcell"] {
      background-color: #f3f6f8;
    }
  }
}

.rsuite-table {
  flex-grow: 1;
}

.clickable-row {
  cursor: pointer;
}

.rs-table-cell-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.check-cell-wrapper {
  line-height: "40px";
}

.cell-popover {
  width: 150px;
}

.custom-tooltip {
  max-width: 60vw !important;
  max-height: 55vh !important;
  overflow: hidden !important;
  background-color: #d3d3d3 !important;
  color: black !important;
  box-shadow: 4px 6px 43px var(--side-nav-shadow-color);
  border: 1px solid #eaecf0;
  font-size: 10px !important;
  user-select: text;
}
