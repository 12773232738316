.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid var(--border-color);
  background-color: var(--color-white);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;

    .table-footer__rows-per-page-label {
      margin-right: 8px;
    }

    .table-footer__select {
      margin-right: 16px;
      width: 65px;
    }

    .table-footer__pagination {
      margin: 0 8px;
    }
  }
}
