.promotion-container {
  margin: 20px 0;

  .rs-row {
    margin-bottom: 5px;
    color: #324051;
  }

  .promotion-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #324051;
  }
  .discount-value {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: right;
  }
}

.rs-popover {
  --rs-bg-overlay: #eaf0f5;
  --rs-list-bg: #eaf0f5;

  .rs-popover-title ~ .rs-popover-content {
    margin-top: 0;
  }
}

.info-icon {
  height: 20px;
  width: 20px;
  color: #66798d;
}

.promotion-icon {
  height: 20px;
  width: 20px;
  color: #66798d;
  position: relative;
  left: 6px;
  top: 5px;
}

// add a background circle around promotion icon

.promotion-wrapper-icon {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #eaf0f5;
  position: relative;
  bottom: 6px;
  right: 6px;
}

.popover-title {
  font-size: 14px;
  font-family: 500;
  font-family: "Montserrat", sans-serif;
}

.popover-content {
  font-size: 12px;
  font-family: 400;
  font-family: "Montserrat", sans-serif;
}

.coin-hand-icon {
  height: 20px;
  width: 20px;
  color: #66798d;
  position: relative;
  left: 6px;
  top: 2px;
}

.coin-hand-wrapper-icon {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #eaf0f5;
  position: relative;
  bottom: 6px;
  right: 6px;
}

.breakfast-icon {
  height: 20px;
  width: 20px;
  color: #66798d;
  position: relative;
  left: 6px;
  top: 4px;
}

.breakast-wrapper-icon {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #eaf0f5;
  position: relative;
  bottom: 6px;
  right: 6px;
}
