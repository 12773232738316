img.img-align {
  cursor: pointer;
  position: relative;
  left: 5px;
  width: 16px;
}
.info-value {
  white-space: normal;
  word-wrap: break-word;
  max-width: 150px;
}
