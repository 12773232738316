.calendar {
  --text-color: #324051;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #eaf0f5;

  display: block;
  position: relative;
  background: var(--neutral-color);
  overflow-x: scroll;

  .navigation {
    gap: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;

    .icon {
      --rs-btn-default-bg: transparent;
      width: 3.4rem;
      height: 3.5rem;
      padding: 0;

      &:disabled {
        visibility: hidden;
      }
    }

    .rs-picker-input {
      --rs-picker-value: var(--text-color);
    }
  }

  .month {
    padding-top: 1rem;

    .week {
      flex-wrap: nowrap;
    }

    .cell {
      background-color: #fff;
      border: 2px solid var(--border-color);
      margin: 0.2rem;
      padding: 12px;
      cursor: pointer;
      border-radius: 8px;

      &:not(.disabled):hover {
        background: var(--bg-color);
        transition: 0.5s ease-out;
        border: 2px solid #5f70ee !important;
      }

      &.hidden {
        cursor: default;
        opacity: 0;
      }

      .text-content {
        display: grid;
        padding-bottom: 0.75rem;
        color: var(--text-color);
        min-height: 75px;

        .date {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.005em;
          justify-self: flex-end;
        }

        .available {
          display: flex;
          align-items: center;
          gap: 5px;

          .badge {
            --bs-badge-padding-x: 8px;
            --bs-badge-padding-y: 8px;
            --bs-bg-opacity: 1;
          }

          .inventory {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.015em;
            text-align: left;
          }
        }

        .rate {
          background-color: transparent;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          width: 10rem;
        }
      }

      .toggle-wrapper {
        border-color: #e8e9ed;
        border-top-style: solid;
        border-top-width: 1px;
        padding: 12px 0px 0px;
        --rs-toggle-bg: #324051;
        --rs-toggle-checked-bg: #5f70ee;
      }

      &.available {
        .badge {
          background-color: #73ba7f;
        }
      }

      &.unavailable {
        background: #f9edee;
        border: 2px solid #c25254;
        color: #902325;
        .text-content {
          color: #902325;
        }
        .toggle-wrapper {
          border-color: #c25254;
        }
        .badge {
          background-color: #c25254;
        }
      }

      &.limited {
        --rs-toggle-checked-disabled-bg: #afbff6;
        background: linear-gradient(
          45deg,
          #fef7eb 0%,
          #ffffff 0%,
          #ffffff 0.94%,
          #fef7eb 0.94%,
          #fef7eb 1.89%,
          #ffffff 1.89%,
          #ffffff 2.83%,
          #fef7eb 2.83%,
          #fef7eb 3.77%,
          #ffffff 3.77%,
          #ffffff 4.71%,
          #fef7eb 4.71%,
          #fef7eb 5.66%,
          #ffffff 5.66%,
          #ffffff 6.6%,
          #fef7eb 6.6%,
          #fef7eb 7.54%,
          #ffffff 7.54%,
          #ffffff 8.49%,
          #fef7eb 8.49%,
          #fef7eb 9.43%,
          #ffffff 9.43%,
          #ffffff 10.37%,
          #fef7eb 10.37%,
          #fef7eb 11.31%,
          #ffffff 11.31%,
          #ffffff 12.26%,
          #fef7eb 12.26%,
          #fef7eb 13.2%,
          #ffffff 13.2%,
          #ffffff 14.14%,
          #fef7eb 14.14%,
          #fef7eb 15.08%,
          #ffffff 15.08%,
          #ffffff 16.03%,
          #fef7eb 16.03%,
          #fef7eb 16.97%,
          #ffffff 16.97%,
          #ffffff 17.91%,
          #fef7eb 17.91%,
          #fef7eb 18.86%,
          #ffffff 18.86%,
          #ffffff 19.8%,
          #fef7eb 19.8%,
          #fef7eb 20.74%,
          #ffffff 20.74%,
          #ffffff 21.68%,
          #fef7eb 21.68%,
          #fef7eb 22.63%,
          #ffffff 22.63%,
          #ffffff 23.57%,
          #fef7eb 23.57%,
          #fef7eb 24.51%,
          #ffffff 24.51%,
          #ffffff 25.46%,
          #fef7eb 25.46%,
          #fef7eb 26.4%,
          #ffffff 26.4%,
          #ffffff 27.34%,
          #fef7eb 27.34%,
          #fef7eb 28.28%,
          #ffffff 28.28%,
          #ffffff 29.23%,
          #fef7eb 29.23%,
          #fef7eb 30.17%,
          #ffffff 30.17%,
          #ffffff 31.11%,
          #fef7eb 31.11%,
          #fef7eb 32.06%,
          #ffffff 32.06%,
          #ffffff 33%,
          #fef7eb 33%,
          #fef7eb 33.94%,
          #ffffff 33.94%,
          #ffffff 34.88%,
          #fef7eb 34.88%,
          #fef7eb 35.83%,
          #ffffff 35.83%,
          #ffffff 36.77%,
          #fef7eb 36.77%,
          #fef7eb 37.71%,
          #ffffff 37.71%,
          #ffffff 38.66%,
          #fef7eb 38.66%,
          #fef7eb 39.6%,
          #ffffff 39.6%,
          #ffffff 40.54%,
          #fef7eb 40.54%,
          #fef7eb 41.48%,
          #ffffff 41.48%,
          #ffffff 42.43%,
          #fef7eb 42.43%,
          #fef7eb 43.37%,
          #ffffff 43.37%,
          #ffffff 44.31%,
          #fef7eb 44.31%,
          #fef7eb 45.25%,
          #ffffff 45.25%,
          #ffffff 46.2%,
          #fef7eb 46.2%,
          #fef7eb 47.14%,
          #ffffff 47.14%,
          #ffffff 48.08%,
          #fef7eb 48.08%,
          #fef7eb 49.03%,
          #ffffff 49.03%,
          #ffffff 49.97%,
          #fef7eb 49.97%,
          #fef7eb 50.91%,
          #ffffff 50.91%,
          #ffffff 51.85%,
          #fef7eb 51.85%,
          #fef7eb 52.8%,
          #ffffff 52.8%,
          #ffffff 53.74%,
          #fef7eb 53.74%,
          #fef7eb 54.68%,
          #ffffff 54.68%,
          #ffffff 55.63%,
          #fef7eb 55.63%,
          #fef7eb 56.57%,
          #ffffff 56.57%,
          #ffffff 57.51%,
          #fef7eb 57.51%,
          #fef7eb 58.45%,
          #ffffff 58.45%,
          #ffffff 59.4%,
          #fef7eb 59.4%,
          #fef7eb 60.34%,
          #ffffff 60.34%,
          #ffffff 61.28%,
          #fef7eb 61.28%,
          #fef7eb 62.23%,
          #ffffff 62.23%,
          #ffffff 63.17%,
          #fef7eb 63.17%,
          #fef7eb 64.11%,
          #ffffff 64.11%,
          #ffffff 65.05%,
          #fef7eb 65.05%,
          #fef7eb 66%,
          #ffffff 66%,
          #ffffff 66.94%,
          #fef7eb 66.94%,
          #fef7eb 67.88%,
          #ffffff 67.88%,
          #ffffff 68.83%,
          #fef7eb 68.83%,
          #fef7eb 69.77%,
          #ffffff 69.77%,
          #ffffff 70.71%,
          #fef7eb 70.71%,
          #fef7eb 71.65%,
          #ffffff 71.65%,
          #ffffff 72.6%,
          #fef7eb 72.6%,
          #fef7eb 73.54%,
          #ffffff 73.54%,
          #ffffff 74.48%,
          #fef7eb 74.48%,
          #fef7eb 75.42%,
          #ffffff 75.42%,
          #ffffff 76.37%,
          #fef7eb 76.37%,
          #fef7eb 77.31%,
          #ffffff 77.31%,
          #ffffff 78.25%,
          #fef7eb 78.25%,
          #fef7eb 79.2%,
          #ffffff 79.2%,
          #ffffff 80.14%,
          #fef7eb 80.14%,
          #fef7eb 81.08%,
          #ffffff 81.08%,
          #ffffff 82.02%,
          #fef7eb 82.02%,
          #fef7eb 82.97%,
          #ffffff 82.97%,
          #ffffff 83.91%,
          #fef7eb 83.91%,
          #fef7eb 84.85%,
          #ffffff 84.85%,
          #ffffff 85.8%,
          #fef7eb 85.8%,
          #fef7eb 86.74%,
          #ffffff 86.74%,
          #ffffff 87.68%,
          #fef7eb 87.68%,
          #fef7eb 88.62%,
          #ffffff 88.62%,
          #ffffff 89.57%,
          #fef7eb 89.57%,
          #fef7eb 90.51%,
          #ffffff 90.51%,
          #ffffff 91.45%,
          #fef7eb 91.45%,
          #fef7eb 92.4%,
          #ffffff 92.4%,
          #ffffff 93.34%,
          #fef7eb 93.34%,
          #fef7eb 94.28%,
          #ffffff 94.28%,
          #ffffff 95.22%,
          #fef7eb 95.22%,
          #fef7eb 96.17%,
          #ffffff 96.17%,
          #ffffff 97.11%,
          #fef7eb 97.11%,
          #fef7eb 98.05%,
          #ffffff 98.05%,
          #ffffff 98.99%,
          #fef7eb 98.99%,
          #fef7eb 99.94%,
          #ffffff 99.94%
        );
        color: var(--text-color-light) !important;

        .badge {
          background-color: #dfa43a;
        }

        .inventory {
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }

      &.closed {
        background: #eaf0f5;
        border: 1px solid #c1cdda;
        color: #66798d;
        .text-content {
          color: #66798d;
        }
        .toggle-wrapper {
          border-color: #c1cdda;
        }
        .badge {
          background-color: #66798d;
        }
      }

      &.selected {
        background: #eceefc;
        border: 2px solid #5f70ee;
        color: #5f70ee;
        .text-content {
          color: #5f70ee;
        }
        .toggle-wrapper {
          border-color: #5f70ee;
        }
        .badge {
          background-color: #5f70ee;
        }
      }
    }

    .disabled,
    .disabled * {
      --bs-bg-opacity: 0.5;
      --rs-toggle-checked-disabled-bg: #afbff6;
      color: var(--text-color-light) !important;
      cursor: not-allowed;
    }
  }
}

.rs-toggle-sm .rs-toggle-presentation {
  border-radius: 12px;
  height: 20px;
  min-width: 36px;
}
.rs-toggle-sm .rs-toggle-presentation:after {
  border-radius: 8px;
  height: 16px;
  width: 16px;
}
.rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:after {
  margin-left: -18px;
}
.rs-toggle-sm .rs-toggle-label {
  color: #324051;
}
