.calendar-guide-container {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 16px;
  .calendar-guide-sub-container {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    .calendar-guide-header {
      color: #324051;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      background-color: white;
    }
    .calendar-guide-top-row {
      border-spacing: 0 5px;
      margin-left: 4px;
      .dollar-text {
        top: 1px;
        right: 2px;
      }
    }
    .calendar-guide-row {
      border-spacing: 0 2.5px;
      margin-left: 2px;
    }
    .rs-col {
      padding-left: 0px !important;
    }
    .available {
      background-color: #73ba7f;
    }
    .sold-out {
      background-color: #c84444;
    }
    .restrictions-apply {
      background-color: #dbac1c;
    }
    .closed {
      background-color: grey;
    }
    .text-align {
      white-space: nowrap;
      color: #656464;
      position: relative;
      bottom: 2px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}

.calendar-guide-container.with-bulk-update {
  position: absolute;
  top: 573px;
}
