.maintenance-modal {
  .modal-content {
    padding: 2rem;
    background-color: #fafafc;
  }
  .modal-header {
    justify-content: center;
    color: #344a6f;
    border-bottom: none;

    .modal-title {
      font-size: 2.2rem;
      font-weight: 700;
      text-align: center;
    }
  }
  .modal-body {
    font-size: 1.5rem;
    font-weight: 400;
    color: #344a6f;
  }
  .modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;

    .btn {
      background-color: #344a6f;
      color: var(--bs-btn-color);
    }
  }
  .row {
    margin-bottom: 2rem;
  }
}
