.manageari-page {
  margin-top: 2rem;
  padding-top: 12px;
  background-color: #ffffff;
  border-top: 1px solid #c1cdda;
  .manageari-page-container {
    border-right: 1px solid #c1cdda;
  }
  .manageari-tabcontent {
    padding: 0 1rem;
    gap: 0;
    white-space: nowrap;
    overflow-x: auto;
    .rs-nav-tabs {
      overflow-x: auto;
      padding: 0;
    }
  }
  .manageari-table {
    margin-top: 5px;
    padding-left: 10px;
  }
  .manageari-error-container {
    display: flex;
    gap: 10px;
    padding: 10px;
    margin: 10px;
    border: 1px solid red;
    border-radius: 5px;
    background-color: #fff4f4;
    color: #d9534f;
  }
  .custom-row {
    background-color: #eaf0f5;
    overflow: hidden;
    .rs-table-cell {
      background-color: #eaf0f5;
      color: #324051;
      font-weight: 700;
      border-left: none;
      border-right: none;
      height: 60px !important;
    }
  }
  .rs-table-cell-content {
    justify-content: center !important;
    padding: 10px 8px !important;
    .manageari-input {
      width: 100%;
      border: 1px solid #c1cdda;
      padding: 5px;
      border-radius: 5px;
      height: 25px;
      padding-left: 8px;
      padding-right: 12px;
    }
    .manageari-toggle {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .right-align-table-tax {
      text-align: left;

      .tax-info-icon {
        margin-right: 5px;
      }
    }
  }

  .rs-nav-tabs .rs-nav-item {
    background-color: #eaf0f5;
    border-radius: 0;
    margin-right: 0.1rem;
  }
  .rs-nav-tabs .rs-nav-item-active {
    background-color: #ffff;
    color: #5f70ee;
  }
  .rs-tab-panel {
    padding: 1rem;
    .manageari-header {
      background-color: #eaf0f5;
      color: #324051;
      font-weight: 700;
    }
  }
  .manageari-btn-container {
    display: flex;
    gap: 10px;
    float: right;
    padding: 2rem;
    .manageari-reset {
      width: 150px;
      background-color: #fff; /* Change to your desired color */
      color: #141f2d;
      border: 1px solid #141f2d;
      font-weight: 600;
    }
    .manageari-save {
      width: 150px;
      background-color: #ff5a37; /* Change to your desired color */
      color: #fff;
      font-weight: 600;
    }
  }
  .upload-ari {
    padding: 2rem;
    height: 100%;
    background: #fafbfc;
    border-radius: 5px;

    .upload-file-btn.rs-btn {
      font-weight: 500;
      height: 48px;
      background-color: #324051;
    }
  }
  .download-button {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
    margin-bottom: 1.25rem;
  }
  .rs-table-cell-bordered .rs-table-cell {
    color: #324051;
    border-bottom: #b9babf;
    height: 60px !important;
    border-left: none;
    border-right: none;
  }
  .rs-table-cell-header .rs-table-cell-content {
    text-align: left !important;
  }
}

.manageari-checkbox {
  width: 20px !important;
  height: 20px !important;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #5f70ee;
}
.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #5f70ee;
}

.rs-tabs-content {
  position: relative;
  bottom: 20px;
}
