.ht-tabs {
  --tab-font-size: 13px;
}

.tablistBar {
  display: flex;
  flex-direction: row;
}

.scrollButton {
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    bottom: 0;
    content: "";
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  button {
    background-color: #fff;
    align-items: center;
    border: 1px solid transparent;
    border-radius: unset;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    width: 50px;
    height: 40px;
    padding: 5px 10px !important; // override rs-btn padding

    &:focus,
    &:hover {
      outline: none;
      background-color: #fff !important;
    }
  }
}

.tablist {
  box-sizing: border-box;
  flex-grow: 1;
  overflow-x: hidden; // hide scrollbar as we have controls now
  position: relative;

  ul {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
}

.ht-tab {
  display: inline-block;
  margin: 0 1px 0 0;
  padding: 0;
  background-color: #eaf0f5;
  color: #343434;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  border-bottom-width: 0;

  span {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: var(--tab-font-size);
    justify-content: center;
    margin: 0;
    min-width: var(--tab-min-width);
    position: relative;
    padding: 12px 20px;
    letter-spacing: 0;
  }

  &.active {
    background-color: #fff !important;
    border-top-width: 2px;
    color: #5f70ee;
    font-weight: 600;
    border-top-color: #5f70ee;
  }
}
