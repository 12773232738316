.calender-view-page {
  margin-top: 2rem;
  padding-top: 2rem;
  background-color: #ffffff;
  border-top: 1px solid #c1cdda;

  .rs-row {
    margin-right: 0;
    margin-left: 0;

    .right-panel-wrapper {
      box-shadow: 0px 4px 12px 0px #3240514d;
      border-radius: 8px;
      padding: 20px;
      min-height: 850px;
    }

    .bulk-update-panel-wrapper {
      padding: 10px 20px;
    }
  }
}
