html {
  font-size: 62.5% !important;
  overflow: hidden;
}

body {
  margin: 0;
  background-color: #e0e1e3 !important;
  font-size: 1.4rem;
}

p {
  word-wrap: break-word;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-bold {
  font-weight: bold;
}

.link {
  color: #768ab1;
  cursor: pointer;
}

.link:hover {
  color: #e0102a;
}

.full-btn {
  height: 3.6rem;
  border-radius: 8px;
}

.clear {
  clear: both;
}

/*
  Quill Input Editor
*/
.input-editor {
  border: 2px solid rgba(180, 187, 198, 0.5);
  border-radius: 9px;
}

.input-editor .ql-toolbar {
  border: none;
  border-bottom: 2px solid rgba(180, 187, 198, 0.5);
}

.input-editor .ql-container {
  border: none;
  padding: 10px 2px;
}

.input-editor .ql-container .ql-editor {
  color: #9e9ea8;
}

.modal-footer .btn {
  background: #ededf3;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #261f2b;
  padding: 1rem 1.5rem;
  min-width: 150px;
}

.add-team-member-footer {
  justify-content: center !important;
}

.add-team-invite-btn {
  background: #e0102a !important;
  color: #ffffff !important;
}

/*
Tooltip
*/
.ht-tooltip .tooltip.show {
  opacity: 1 !important;
}

.ht-tooltip .tooltip-inner {
  background-color: #ffffff !important;
  max-width: none !important;
  width: 300px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #263e4c;
  padding: 20px !important;
  text-align: center;
}

.ht-tooltip .tooltip-header {
  font-weight: 700;
  font-size: 16px;
}

.progress-bar.bg-success {
  background-color: #92e251 !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ensure the loader is on top of everything */
}
.no-prop-span {
  margin-top: 0 !important;
  font-size: 1.2rem;
  color: #7d8fb3;
  font-family:
    "Montserrat",
    Roboto,
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    "Segoe UI",
    "Helvetica Neue",
    Arial,
    sans-serif;
}
.rs-picker-popup {
  z-index: 9999 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.embolden {
  font-weight: 700;
  cursor: pointer;
}
.embolden:hover {
  text-decoration: underline;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
