.manageari-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .daterange-container {
    position: relative;
    bottom: 8px;
    left: 3px;
  }
}

.manageari-button {
  width: 150px;
  position: relative;
  bottom: 8.5px;
  left: 24px;
  font-weight: 500;
  background-color: #ff5a37; /* Change to your desired color */
  &:hover,
  &:active,
  &:focus {
    background-color: #ff5a37 !important; // Restrict hover, active, and focus states to the same color
  }
}
