.upload-file-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 30px 16px 30px;
  background-color: #eaf0f5;
  border-radius: 16px;
  border: 2px dashed #c1cdda;
  text-align: center;
  align-items: center;

  .upload-btn {
    font-weight: 500;
    border: 1px solid #082b42;
    color: #082b42;
  }

  img {
    width: 24px;
    height: 24px;
    align-self: center;
  }

  .drag-text {
    color: #324051;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
    align-self: center;
    margin-bottom: 0.7rem;
  }

  .or {
    color: #324051;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
    align-self: center;
    margin-bottom: 0.7rem;
  }

  .support-file-text {
    color: #66798d;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.015em;
    align-self: center;
    margin-top: 0.7rem;
  }
}

.uploaded-files {
  background-color: #eaf0f5;
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .file-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .file-detail {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    span {
      word-break: break-all;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.015em;
      text-align: left;

      &:first-of-type {
        color: #324051;
      }
      &:last-of-type {
        color: #66798d;
      }
    }
  }

  .remove-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: auto;
  }
}
