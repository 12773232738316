.sidebar {
  width: 6.4rem;
  background-color: #ffffff !important;
  stroke: #ddecf4;
  filter: drop-shadow(0px 1px 3px rgba(0, 81, 175, 0.1));
  height: calc(100vh - 12rem);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  transition: width 300ms ease-in-out;

  .sidebar-link-text {
    width: 0;
    opacity: 0;
    font-size: 1.4rem;
    word-break: keep-all;
    white-space: nowrap;
    transition: opacity 300ms ease-in-out;
  }

  &.open {
    width: 220px;
    position: fixed;
    z-index: 99;

    .sidebar-list-item .sidebar-link.active .sidebar-link-text {
      color: #202b40;
      font-weight: 600;
    }

    .sidebar-link-text {
      opacity: 1;
      width: 100%;
    }
  }
}

.top-sidebar {
  width: 100%;
  padding-bottom: 4rem;
}

.bottom-sidebar {
  width: 100%;
}

.sidebar-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-list-item {
  list-style: none;
  width: 100%;
  height: 5rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}
.bottom-sidebar {
  .sidebar-list-item {
    opacity: 50%;
  }
  .help-center {
    position: relative;
    opacity: 100%;
  }
}
.sidebar-link {
  display: flex;
  position: relative;
  width: 100%;
  text-decoration: none;
  height: 100%;
  align-items: center;
  color: #344a6f;
  padding-left: 1.2rem;

  .active::after {
    content: "";
    position: absolute;
    right: 0;
    width: 0.3rem;
    height: 100%;
  }

  > i {
    font-size: 2rem;
    margin-right: 1rem;
    color: #344a6f;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f4f8;

    &.icon-lightbulb {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.active > i {
    color: #ffffff;
    background-color: #344a6f;
  }

  &.menu-icon-btn {
    border: none;
    background: none;

    &.active > i {
      display: none;
    }
  }

  .custom-nav-icon {
    padding: 5px 10px;
    svg {
      position: relative;
      bottom: 1px;
    }
  }

  .custom-default svg {
    stroke: none;
  }

  & > .custom-default {
    display: block;
  }

  & > .custom-active {
    display: none;
  }

  &.active > .custom-default {
    display: none;
  }

  &.active > .custom-active {
    display: block;
  }
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 22px;
  border-top: 2px solid #ccc;
}
