.footer {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  height: 6rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #EDEFF2;
}

.copy-right-text {
  margin-left: 5rem;
}