$container-shadow: 0.5rem 0.5rem 2rem 0 rgba(black, 0.2);
$border-config: solid 1px #e8e9ed;
.notification {
  position: relative;

  .notification__separator {
    border: 1px solid #eee;
  }

  .notification__button-active {
    background: #ffffff;
    box-shadow: 0 0 4px 1px #7f8df1;
  }

  .notification__button {
    margin-right: 2rem;
    background: #ffffff;
    position: relative;
    cursor: pointer;

    .notification__icon {
      padding-right: 1rem;
      width: 3rem;
    }

    .notification__badge {
      margin-left: 1rem;
      background: #5f70ee;
      width: 3.3rem;
      height: 2.3rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
    }

    &:hover {
      background: #eceefc;
      border-radius: 8px;
      box-shadow: 0 0 4px 1px var(--surfacehighlight-strong);
    }
  }

  .notification__popup {
    display: block;
    cursor: default;
    position: absolute;
    z-index: 999;
    top: 4rem;
    left: -25rem;
    width: 600px;
    height: 550px;
    font-weight: 300;
    background: white;
    border-radius: 0.8rem;
    box-sizing: border-box;
    box-shadow: $container-shadow;
    animation-name: dropPanel;
    animation-iteration-count: 1;
    animation-duration: 0.75s;
    padding: 20px;
    .notification__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    &.zero__notification {
      height: auto;
      max-height: fit-content;
    }
    .notification__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .notification__close {
      background: none;
      padding: 0 !important;
    }
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      right: 0;
      width: 0;
      height: 0;
      transform: translate(-25rem, -100%);
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.75rem solid white;
    }

    .notification__content {
      width: 100%;
      max-height: 95%;
      margin-top: 0.5rem;
      overflow-y: scroll;
      -ms-overflow-style: none;
      .notification__content__loader {
        margin: 20px auto;
        padding: 10px;
        text-align: center;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      .notification__tile {
        padding: 1rem;
        background: #ffffff;
        height: 10rem;
        margin-top: 0.2rem;
        display: flex;
        border-bottom: $border-config;
        &.active {
          background: #eaf0f5;
          border-radius: 8px;
          border: $border-config;
          .notification__dot,
          .notification__tile__badge {
            visibility: visible;
            font-weight: 500;
          }
        }
        .notification__overlay {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
        .notification__dot {
          height: 1.5rem;
          width: 1.5rem;
          background-color: #5f70ee;
          border-radius: 50%;
          display: inline-block;
          margin: 0.5rem 0.5rem;
          visibility: hidden;
        }
        .notification__tile__badge {
          color: #0b121a;
          background: #d7777f;
          visibility: hidden;
          width: 11rem;
          text-align: center;
          font-weight: 400;
        }
        .notification__message {
          height: 100%;
          width: 100%;
          .notification__message__header {
            padding: 0.2rem 1rem;
            height: 25%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
          }
          .notification__message__body {
            height: 75%;
            display: flex;
            padding: 10px;
            .notification__message__body__text {
              padding-right: 1rem;
              font-size: 1.1rem;
            }
            .notification__message__body__operations {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.delete-animation {
  animation: slideAndCollapse 0.5s forwards;
  margin: 0;
  padding: 0;
  visibility: hidden;
}

@keyframes slideAndCollapse {
  0% {
    opacity: 1;
    transform: translateX(0);
    max-height: 200px;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
    max-height: 0;
    margin-top: 0;
    padding: 0;
    border: 0;
  }
}
