.promotions-container {
  .promotions-label {
    color: #344a6f;
    font-size: 24px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }
  .promotions-label-time {
    color: #929eb8;
    font-size: 1rem;
  }
  .promo-table-header {
    margin-right: auto;
    font-size: 18px;
    color: #344a6f;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
  }
  .mglass {
    display: inline-block;
    pointer-events: none;
    font-size: 16px;
    font-weight: bold;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }
  .dd-option {
    text-align: center;
    font-size: 13px;
    cursor: pointer;
  }
  .promotions-filter {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    .promotions-filter-form {
      margin-left: 1rem;
      select {
        background-color: transparent;
        font-size: 1.2rem;
        padding-top: 0;
        font-weight: 600;
      }
    }
  }

  .pie-container {
    margin-top: 1rem;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 30px 20px;
  }

  .promotions-pie {
    height: 268px;
    background-color: #ffffff;
    color: #344a6f;
  }

  .promotion-kpi {
    height: 140px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    color: #344a6f;

    .promotion-kpi-header {
      font-size: 14px;
    }
    .promotion-kpi-value {
      font-size: 24px;
      font-weight: 500;
      .promotion-kpi-value-span {
        padding: 5px;
      }
      .promotion-percent {
        font-size: 12px;
        background-color: #ecfdf5;
        padding: 5px;
        margin-left: 1rem;
        border-radius: 5px;
        color: #005e36;
        position: relative;
        top: -2px;
        .fa {
          margin-right: 3px;
        }
      }
      .promotion-percent-negative {
        background-color: #ffeaea;
        color: #5e0000;
      }
    }
    .promotion-kpi-previous {
      font-size: 12px;
      color: #929eb8;
    }
  }
  .promo-table-container {
    margin-top: 1rem;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 2rem 1rem;
    .table-details {
      margin-bottom: 2rem;
      display: flex;
    }
    th {
      background: #e5eaf2;
      line-height: 2em;
      font-weight: 600;
      color: #344a6f;
      font-size: 14px;
    }
    tr:hover {
      --bs-table-hover-bg: #f3f5f9;
    }
    td {
      color: #344a6f;
      line-height: 2em;
      font-size: 14px;
    }
    .promo-status.Inactive {
      color: #fc424a;
    }
    .promo-status.Draft {
      color: #ffab00;
    }
    .pagination {
      --bs-pagination-active-bg: #344a6f;
      --bs-link-color: #344a6f;
    }
  }
  .promotions-create-promo {
    background-color: #ffffff;
    padding: 30px 20px 30px;
    margin-top: 10px;
    border-radius: 10px;
    .promotions-create-promo-header {
      color: #344a6f;
      font-size: 2.2rem;
      img {
        margin-right: 10px;
      }
      span {
        color: #344a6f;
        font-weight: 600;
      }
    }
    .promotions-create-promo-desc {
      color: #929eb8;
      font-size: 1.2rem;
    }
    button,
    button:active {
      background-color: #344a6f;
      border: 0;
      margin-top: 40px;
    }
  }
}
.confirm-disable-modal {
  .modal-body {
    height: 100px;
    padding: 3rem 1rem;
  }
  .modal-footer {
    .btn {
      width: 100px;
      padding: 0.5rem;
      color: #344a6f;
      background-color: #ffffff;
    }
    .disable-btn {
      background-color: #344a6f;
      color: #ffffff;
    }
  }
}
.create-promo-modal {
  .modal-content {
    padding: 2rem 3rem 0;
    background-color: #fafafc;
  }
  .modal-title {
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 15px;
    color: #344a6f;
  }
  .modal-body {
    font-size: 1.2rem;
    color: #344a6f;
    min-height: 400px;
    min-width: 600px;
  }
  .create-promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .create-promo-body {
      min-height: 400px;
    }
  }
  .create-promo-footer {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    button {
      font-size: 1.2rem;
    }
  }
  .summary-footer {
    justify-content: flex-start;
  }
}

.promotions-text {
  font-size: 14px !important;
}

.btn-outline-secondary {
  font-size: 14px !important;
}
