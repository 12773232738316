.she-container {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0;

  .page-header {
    color: #324051;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
    margin-bottom: 40px;
    margin-left: 10px;
  }

  .toggle-container {
    display: flex;
    border-radius: 24px;
    background-color: #ffffff !important;
    border: 1px solid #c1cdda;
    align-items: center;
    padding: 4px;
    gap: 8px;
    bottom: 18px;

    button {
      display: flex;
      border-radius: 24px;
      background-color: #ffffff !important;
      height: 40px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      flex-shrink: 0;
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #324051 !important;
    }

    .active-view {
      background-color: #556ff6 !important;
      color: #ffffff !important;
      border-radius: inherit !important;
      font-weight: 500;
      .rs-upload {
        stroke: #ffffff !important;
      }
    }
  }
}

.quick-view-active {
  // Add styles for the quick view active state
  margin-left: 10px;
  padding-bottom: 24px;
  height: 0px !important;
}

.manage-ari-active {
  margin-left: 16px;
  padding-bottom: 24px;
  height: 0px !important;
}

.rs-btn-start-icon {
  svg {
    height: 20px;
    width: 20px;
  }
}
