.spinner {
  z-index: 20;
  text-align: center;
  align-items: center;
  display: flex;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: inherit;
  padding: 10px 0;
  background-color: #fff;

  .loader-icon {
    height: 50px;
  }

  svg {
    margin-bottom: 20px;
  }

  @supports not (
    (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))
  ) {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }

  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}
